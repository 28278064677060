import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBookOpen, faCheckCircle, faAngleRight } from "@fortawesome/free-solid-svg-icons"



export default props => {
    const {topic, tags, level, description, path, title, readingTime} = props.node;
    
  return (

    
    <div className="md:mx-2 flex-grow rounded-b-lg shadow min-w-full md:min-w-0 w-1/2 md:w-1/3 my-2 ">
      
      <div className="justfy-center  border-t-4 border-blue-600 h-full items-center" >
    
    
    
       
        <h1 className="py-3 text-lg uppercase text-gray-800 font-bold tracking-wider bg-gray-100 py-2"> {title}</h1>


{/* topic, minutes to read and level */}
<div className="flex flex-wrap mt-4">

{/* topic and level */}
<div className="flex-col flex-grow">

            <div className="text-xs text-gray-600">
          <h1 className="text-base mt-2 uppercase font-semibold text-gray-700 ">
              {topic}
          </h1>
                {level} 

            </div>  
</div>

{/* minutes and icon */}

          <div className="flex-grow">

            <div>
                <FontAwesomeIcon icon={faBookOpen}  className="opacity-75  fa-2x"/>
            </div>

            <div className="text-gray-700 text-xs">
                {readingTime} minutes
            </div> 
          </div>
        
</div>
        
        
     
      {/* description         */}
      <div className="text-sm bg-gray-100 text-gray-700 mt-4 p-1 px-2 md:p-2  md:px-6">
        {` ${description.slice(0,88)}...`  }  
        </div>  

{/* tags and icons */}
      <div className="text-sm mt-2">
        <ul className="text-center flex flex-wrap  justify-center content-end px-1 pt-2 ">
          {tags.map((tag, index) => (
              <li key={index} className="flex-inline-block text-xs px-2 ">
                  <FontAwesomeIcon icon={faCheckCircle}  className="text-green-600 mr-1"/> {tag}
            </li>
          ))}
        </ul>
      </div>


{/* button  */}
        <div className="mx-auto text-center p-2 mt-3 justify-center">
        
          <AniLink fade to={path} className=" pt-2 hover:text-blue-800 text-blue-600  ">
            <span className="p-2 text-sm  font-bold  ">{title} <FontAwesomeIcon icon={faAngleRight} /></span>
          </AniLink>
        </div>

          </div>  


    </div>
  )
}
