import React from 'react'
import { useStaticQuery, Link, graphql } from "gatsby"
import Card from '../card'

export default ()=>{
    const queryTags = useStaticQuery(graphql`
    query {
        allMdx{
            nodes{
            timeToRead    
            frontmatter{
                topic
                title
                tags
                description
                level
                path
            }
           }
        }
    }
    `);
    
    const nodesPost = queryTags.allMdx.nodes.map(
        node => {
            const nodo = {...node.frontmatter, ...{readingTime: node.timeToRead} }
            return nodo
        }
    )
        
   
    return(
        <section className="flex flex-wrap justify-center text-center m-16 flex-stretch ">
            

        {
            nodesPost.map(
                (node, index) => <Card key={index} node= {node} /> 
                )
            }
        


            </section>
    )

}

