import React, {Component} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"




const Quotes = (props)=>{
    return(
        <div className="p-4 leading-loose">
        <div>
        <FontAwesomeIcon icon={faQuoteLeft} className=" fa-2x text-gray-300  ml-2 mb-3"/> 
            </div>
            <div className="w-full leading-10 px-4 md:px-8 py-3 text-2xl font-semibold ">

        {props.children}
            </div>
        <div className="text-right">
        <FontAwesomeIcon icon={faQuoteRight} className=" fa-2x text-gray-300 ml-2 text-right mt-3"/> 
            </div>     
        </div>

    )
    }

export default Quotes
