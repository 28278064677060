import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact, faHtml5, faSass, faNodeJs, faJs, faCss3,}  from "@fortawesome/free-brands-svg-icons"
import GatsbyLogo from '../../assets/Gatsby_Monogram.svg'
import GraphLogo from '../../assets/graphql-2.svg'
import TailwindLogo from '../../assets/tailwindcss-white.svg'


export default ()=>{
    const funcIcone = (iconClasses)=>[
        <FontAwesomeIcon icon={faJs} className={iconClasses} />,
        <FontAwesomeIcon icon={faHtml5} className={iconClasses} />,
        <FontAwesomeIcon icon={faSass} className={iconClasses} />,
        <FontAwesomeIcon icon={faReact} className={iconClasses} />,
        <FontAwesomeIcon icon={faCss3} className={iconClasses} />,
        <FontAwesomeIcon icon={faNodeJs} className={iconClasses} />,        
        ]
     const icone = [...funcIcone("text-white fa-3x w-10 h-10")]    

    return(
        <section className = " w-full  py-3 mt-16  text-center">
            <h1 className="text-3xl text-gray-600 py-2 md:rounded-lg">
                This blog is made with
            </h1>
            <div className="flex justify-center">

            <div className="flex flex-wrap bg-blue-600 justify-center shadow pl-3 rounded-lg">
            
            {icone.map(
                (icona,index) => {
                    return(
                        <div key={index} className="p-3 ">{icona}</div>
                        )
                    }
                    )}
               <div className="p-3"><GatsbyLogo className="w-10 h-10" /></div>
               
              </div>
            </div>

        </section>
    )
}