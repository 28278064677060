import React from 'react'
import Introduction from './introduction'
import Technologies from './technology'
import BlogSlugs from './blogslugs'
import AboutMe from '../../custom-components/aboutMe'
import Quotes from '../../custom-components/quotes'
import { useStaticQuery, graphql } from "gatsby"


export default ()=> {
    const imageQuery = useStaticQuery(graphql`
    query{
      file(relativePath: {eq: "img/editor.png"}) {
        childImageSharp{
          original{
            src
          }
        }
      }
    }
  `);
  const editorImage = imageQuery.file.childImageSharp.original.src;
  
    return (
        <main >
           
            <Introduction />
           <div className="container mt-16 bg-gray-100 text-white shadow bg-blue-700 rounded-lg shadow-lg" >
               <Quotes>
               <span className="">
                   We can see a short distance ahead,</span><span className="md:block text-right"> but we can see plenty there that needs to be done
                   </span>
               </Quotes>
               <h2 className="text-right text-white font-bold md:ml-4 text-2xl p-3 mb-3 tracking-wide">Alan Turing</h2>
           </div>
                
            {/* <AboutMe /> */}
            <BlogSlugs />
            <Technologies />

        </main>
    )
}