import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

export default ()=> {
  const imageQuery = useStaticQuery(graphql`
  query{
    file(relativePath: {eq: "img/avatar.png"}) {
      childImageSharp{
        original{
          src
        }
      }
    }
  }
`);

const avatarImage = imageQuery.file.childImageSharp.original.src;

    return (
        <section className=" mx-auto mt-16 py-2">
        <div className="leading-relaxed flex items-center md:px-2 ">

      <img src={avatarImage} alt="javascript avatar geekynut" className="invisible md:visible h-0 w-0 md:h-24 md:w-24 md:shadow-lg mx-2 rounded-full flex-shrink-0"/>
        <div className="px-6 py-4">
          
        <h2 className="text-xl font-semibold text-gray-800 text-center">Welcome to Geekynut</h2>          
        <p className="text-gray-800 mt-3">
          Geekynut is a blog that has a range of geeky readings with emphasis on web development and technology. Please contact me if you wish to collaborate and add your geeky post.          </p>
        <div className="mt-4 md:mr-4 text-right">
        <a href="/blog" className="text-indigo-600 hover:text-indigo-400 font-semibold text-sm">read more..</a>  
        </div>        
      
      </div>

      </ div>
      </section>
    )
}