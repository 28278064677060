import React, { Component } from "react"
import Layout from "../gatsby-theme-blog/components/layout"
import Footer from "../custom-components/footer"
import MainSection from "../custom-components/homepage/main-section"
import { Helmet } from "react-helmet"


class Index extends Component {
  render() {
    return (
      <div>
        <Layout>
        <Helmet>
                   
          
          <title>Geekynut | blog</title>
        
        </Helmet>
          <MainSection />
        </Layout>
        <Footer />
      </div>
    )
  }
}

export default Index
